<template>
  <div class="home">
    <!--<div class="beta_notice">In Beta</div>-->
    <div class="content">
      <Header />
      <!--  <Summary :summary-text="summaryText" :section-title="sectionTitle" /> -->
      <Portfolio />
      <div class="footer">
        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";

import Portfolio from "@/components/Portfolio.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Home",
  components: {
    Header,

    Portfolio,
    Footer
  },
  data: () => ({
    summaryText:
      "I'm currently working in a dynamic Product team as a Product Manager.<br/>My breadth of hands-on experience in different businesses and functions means I’m able to adapt quickly to different situations and work across teams with a deep understanding of how they fit together.",
    sectionTitle: "About Me"
  })
};
</script>

<style>
.beta_notice {
  font-family: sofiaPro-Regular;
  font-size: 20px;
}

.content {
  position: relative;
  min-height: 100%;
  padding-bottom: 80px;
}

.unline_href {
  text-decoration: underline;
}

.footer {
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 50px;
}

.home {
  margin: unset;
  padding: unset;
  height: 100%;
  width: 100%;
}

@font-face {
  font-family: sofiaPro-light;
  src: url("~@/assets/fonts/sofiapro-light.woff");
}

@font-face {
  font-family: sofiaPro-Regular;
  src: url("~@/assets/fonts/sofiapro-regular.woff");
}

@font-face {
  font-family: sofiaPro-Medium;
  src: url("~@/assets/fonts/sofiapro-medium.woff");
}
</style>
