<template>
  <div class="summary_container">
    <div
      class="goHomeBtnIndependent"
      v-if="this.$route.path != '/'"
      @click="goHome()"
    >
      Home
    </div>
    <div class="section_title">
      {{ sectionTitle }}
      <span
        class="goHomeBtnRow"
        v-if="this.$route.path != '/'"
        @click="goHome()"
        >Home</span
      >
    </div>
    <div class="summary_text" v-html="summaryText"></div>
  </div>
</template>

<script>
export default {
  name: "Summary",
  props: ["summaryText", "sectionTitle"],
  data: () => ({}),
  methods: {
    goHome() {
      this.$router.push("/");
    }
  }
};
</script>

<style scoped>
@media (max-width: 1024px) {
  .summary_text {
    font-size: 17px;
    width: 100%;
    margin-top: 20px;
  }
  .section_title {
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 17px;
    border-top: 1px solid rgba(0, 0, 0, 0.125);
  }
}

@media (max-width: 450px) {
  .goHomeBtnRow {
    display: none;
  }
  .goHomeBtnIndependent {
    display: block;
  }
}
</style>

<style>
.goHomeBtnIndependent {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  display: none;
  width: 100%;
  padding-top: 5px;
  font-size: 20px;
  color: black;
}

.goHomeBtnRow {
  background-color: black;
  color: white;
  font-size: 17px;
  padding: 5px 10px;
}

.goHomeBtnRow,
.goHomeBtnIndependent {
  font-family: sofiaPro-Medium;
}

.section_title {
  text-align: left;
  font-family: sofiaPro-Medium;
  font-size: 22px;
  margin-bottom: 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding-bottom: 10px;
  padding-top: 10px;

  margin-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.summary_text {
  width: 90%;
  margin: 0 auto;
  font-family: SofiaPro-Medium;
  font-size: 22px;
  color: black;
}

.section_title span {
  float: right;
  cursor: pointer;
  transition: 0.4s;
}

.section_title span:hover {
  opacity: 0.4;
}
</style>
