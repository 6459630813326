<template>
  <div>
    <imageModal :image-path="selectedImagePath" ref="imageModalCompt" />
    <div class="global_container">
      <div class="gridRow" v-for="(item, index) in gridData" :key="index">
        <div class="row" :class="{ reverse_columns: !(index % 2) == 0 }">
          <div class="col" :class="{ col_border_left: !(index % 2) == 0 }">
            <div class="grind_image_container">
              <div class="grind_image_container_image">
                <img
                  :class="{ hoverImage: item.enableEnlarge }"
                  @click="showImageModal(item.imagePath, item.enableEnlarge)"
                  v-if="item.imagePath"
                  :src="require('@/assets/portfolio/' + item.imagePath)"
                  :alt="item.altImageTitle"
                />
              </div>
              <div class="grind_image_container_text">
                {{ item.imageCaption }}
                <div class="enlargeButton" v-if="item.enableEnlarge">
                  <img
                    src="@/assets/images/resize_icon.png"
                    alt=""
                    @click="showImageModal(item.imagePath)"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="col bullet_point_container"
            :class="{ col_border_left: !(index % 2) == 1 }"
          >
            <div class="">
              <div class="textIconContainer" v-if="item.textIconImgPath">
                <img
                  :src="require('@/assets/portfolio/' + item.textIconImgPath)"
                  height="60px"
                  alt=""
                />
              </div>
              <div
                class="bullet_point_line"
                :key="index"
                v-for="(bulletPoint, index) in item.bulletPoints"
              >
                {{ bulletPoint }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import imageModal from "./imageModal";
export default {
  name: "GridView",
  components: { imageModal },
  props: ["gridData"],
  data: () => ({
    selectedImagePath: ""
  }),
  methods: {
    showImageModal(itemImagePath, enableEnlarge) {
      if (enableEnlarge) {
        this.selectedImagePath = itemImagePath;
        this.$refs.imageModalCompt.$modal.show("imageModalView");
      }
    }
  }
};
</script>

<style scoped>
@media (max-width: 1024px) {
  .bullet_point_line {
    font-size: 17px;
  }
  .grind_image_container_text {
    margin-bottom: 17px;
  }
}

@media (max-width: 450px) {
  .row {
    display: block;
  }
  .col_border_left {
    border: unset;
  }
  .col {
    height: unset !important;
  }
  .grind_image_container_image img {
    max-width: 150px;
  }
}

.col {
  height: 300px;
}
</style>

<style>
.enlargeButton {
  padding: 10px;
  cursor: pointer;
  transition: 0.4s;
}

.enlargeButton:hover {
  opacity: 0.5;
}

.enlargeButton img {
  height: 20px;
  width: 20px;
}

.textIconContainer {
  margin-bottom: 20px;
}
.reverse_columns {
  flex-direction: row-reverse;
}

.col_border_left {
  border-left: 1px solid rgba(0, 0, 0, 0.125);
}

.bullet_point_line {
  display: block;
  font-family: SofiaPro-Light;
  font-size: 17px;
  color: black;
  padding: 4px 10%;
}
.bullet_point_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.grind_image_container_image img {
  max-width: 100%;
  max-height: 100%;
}

.hoverImage {
  cursor: pointer;
  transition: 0.4s;
}

.hoverImage:hover {
  opacity: 0.5;
}

.grind_image_container_image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90%;
  margin-bottom: 2%;
  max-width: 100%;
  max-height: 100%;
}

.grind_image_container {
  height: 100%;
  padding: 10px;
}

.grind_image_container_text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8%;
  font-family: SofiaPro-Light;
  font-size: 20px;
  color: black;
}

.gridRow {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  padding: 30px;
}
</style>
