import Vue from "vue";
import router from "./router";
import App from "./App.vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import vmodal from "vue-js-modal";

import { BootstrapVue } from "bootstrap-vue";

import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: { id: "G-MTT4FH5QJ2" }
});

Vue.use(BootstrapVue);
Vue.use(vmodal);
//import "vue-js-modal/dist/styles.css";

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
