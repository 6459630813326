import Vue from "vue";
import VueRouter from "vue-router";
import Home from "./views/Home.vue";
import Detail from "./views/Detail.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Portfolio | Theo Bowden - Product Manager",
    component: Home,
    title: "Portfolio | Theo Bowden - Product Manager",
    meta: {
      name: "Portfolio | Theo Bowden - Product Manager"
    }
  },
  {
    path: "/portfolio/:portfolio_type?",
    name: "Theo Bowden - Product Manager | Portfolio",
    component: Detail,
    title: "Portfolio | Theo Bowden - Product Manager",
    meta: {
      name: "Portfolio | Theo Bowden - Product Manager"
    }
  },
  {
    path: "*",
    name: "Not Found",
    component: Home
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
