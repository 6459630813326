<template>
  <div class="home">
    <!--<div class="beta_notice">In Beta</div>-->
    <div class="content">
      <Header />
      <Summary
        :summary-text="selectedPortfolio.summaryText"
        :section-title="selectedPortfolio.sectionTitle"
      />
      <GridView :grid-data="selectedPortfolio.tiles" />
      <div class="footer">
        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Summary from "@/components/Summary.vue";
import GridView from "@/components/GridView.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Detail",
  components: { Header, Summary, GridView, Footer },
  data: () => ({
    portfolios: [
      {
        portfolioName: "octopus_cash_octopus_investments",
        summaryText:
          "I was a member of a small team developing a full-service in-house digital Cash management platform - growing AUM to £300 million. Using an agile approach to enable rapid growth, I independently building short-term solutions to bridge operational gaps, whilst new features were in development. Managing automation projects to build long-term technical solutions. Driving efficiency 4 times over.",
        sectionTitle: "Experience: Octopus Cash | Octopus Investments",
        tiles: [
          {
            imagePath: "octopus/octopus_cash_logo.png",
            imageCaption: "",
            altImageTitle: "Octopus Cash",
            textIconImgPath: "octopus/clipboard_icon.png",
            enableEnlarge: false,
            bulletPoints: [
              "Supported product development through creating Jira tickets, testing releases and participating in sprint planning to ensure that bugs were appropriately prioritised and user feedback was fed into the design process."
            ]
          },
          {
            imagePath: "octopus/octopus_logo.png",
            imageCaption: "",
            altImageTitle: "Octopus Investments",
            textIconImgPath: "octopus/bar_chart_icon.png",
            enableEnlarge: false,
            bulletPoints: [
              "Created forecasts of customer demand using SQL analysis of historical transaction volumes, as input into resource planning for operations and product teams."
            ]
          }
        ]
      },
      {
        portfolioName: "order_management_platform",
        summaryText:
          "Always looking to improve my product skills, I set myself a project to plan and build a web app, from scratch. I chose to build an App that focused on the purchasing side of e-commerce, an area under-served for small and medium business users, as I found when running my own e-commerce business. Along the journey I overcame a huge range technical of challenges and learn as progressed. I’d be more than happy to demo it!",
        sectionTitle: "Project: Order Management Platform",
        tiles: [
          {
            imagePath: "oms/oms_screen_dashboard.png",
            altImageTitle: "OMS Dashboard",
            imageCaption: "Dashboard View",
            textIconImgPath: "oms/stack_icon.png",
            enableEnlarge: true,
            bulletPoints: [
              "After researching and testing a range of frontend, backend and database options I decided to develop a web app using VueJS, Node & MongoDB - this was my first time using each framework."
            ]
          },
          {
            imagePath: "oms/oms_screen_orders.png",
            altImageTitle: "OMS Dashboard",
            imageCaption: "Orders View",
            textIconImgPath: "oms/database_icon.png",
            enableEnlarge: true,
            bulletPoints: [
              "I learnt a lot using MongoDB – its NoSQL data structure was very useful in rapidly prototyping. This flexibility also made queries more difficult to construct, in addition to maintaining continuity among all documents and collections."
            ]
          },
          {
            imagePath: "oms/oms_screen_dashboard.png",
            altImageTitle: "OMS Dashboard",
            imageCaption: "Dashboard View",
            textIconImgPath: "oms/api_icon.png",
            enableEnlarge: true,
            bulletPoints: [
              "I specifically wanted to experiment using API’s to communicate with third party services.",
              "• DHL – Live parcel tracking information",
              "• Google Cloud – Storing invoice and purchase order documents",
              "• Live Currency – Live currency exchange rates (estimated invoice calculations)"
            ]
          },
          {
            imagePath: "oms/oms_screen_accounts.png",
            altImageTitle: "OMS Dashboard",
            imageCaption: "Accounts View",
            textIconImgPath: "oms/group_icon.png",
            enableEnlarge: true,
            bulletPoints: [
              "Authentication was a key aspect to the project - when building a platform to be used by teams, I spent a time researching, experimenting and testing ways to ensure a secure yet seamless authentication process. I built the following features:",
              "•	Permissions",
              "•	Ability to have accounts across multiple organisations",
              "•	Global audit logs"
            ]
          }
        ]
      },
      {
        portfolioName: "ble_beacon_library",
        summaryText:
          "After looking for an easy-to-use library to integrate BLE beacons with Raspberry Pi, to no avail, I wrote a library myself. The library scans for iBeacon and Eddystone BLE beacons using a Raspberry Pi and Python. Visit it here on GitHub.",
        sectionTitle:
          "Project: BLE Beacon Library for Raspbery Pi with Python ",
        tiles: [
          {
            imagePath: "ble_project/bluetooth_logo.png",
            imageCaption: "",
            altImageTitle: "Bluetooth Logo",
            textIconImgPath: "ble_project/github_logo.png",
            enableEnlarge: false,
            bulletPoints: [
              "As of January 2020, the project has 26 Stars, 14 Forks and 4 contributors. It’s great to see others using a solution I built, without ever expecting anyone would even find it."
            ]
          },
          {
            imagePath: "ble_project/python_logo.png",
            imageCaption: "",
            altImageTitle: "Python Logo",
            textIconImgPath: "ble_project/eddystone_logo.png",
            enableEnlarge: false,
            bulletPoints: [
              "The library parses the raw Bluetooth data packets to find the beacons unique identifiers. I designed it to be used with both Google’s Eddystone and Apple’s iBeacon BLE devices."
            ]
          },
          {
            imagePath: "ble_project/raspberry_pi_logo.png",
            imageCaption: "",
            altImageTitle: "Raspberry Pi Logo",
            textIconImgPath: "ble_project/iot_icon.png",
            enableEnlarge: false,
            bulletPoints: [
              "I wrote the library specifically for the Raspberry Pi as there are countless IoT uses for BLE devices, however they often need a device (other than a smartphone) to connect to. The Raspberry Pi provides a great platform to test and deploy IoT solutions with."
            ]
          }
        ]
      },

      {
        portfolioName: "avvel_international",
        summaryText:
          "I co-founded a a tech start-up specialising Bluetooth IoT. We set out empower developers to cheaply and quickly begin developing BLE solutions through selling low cost BLE beacons to customers all over Europe. We soon noticed an opportunity in product development and so began providing a consulting and mobile application development services to our customers.",
        sectionTitle: "Experience: Avvel International",
        tiles: [
          {
            imagePath: "avvel/BlackLRWeb.png",
            imageCaption: "Long Range iBeacon",
            altImageTitle: "Bluetooth Logo",
            textIconImgPath: "avvel/launch_icon.png",
            enableEnlarge: false,
            bulletPoints: [
              "Launched 12 products (compatible with Google Eddystone and Apple iBeacon) after identifying market opportunities and creating product roadmaps and distribution strategies"
            ]
          },
          {
            imagePath: "avvel/avvel_x_beacon.png",
            imageCaption: "Avvel X iBeacon & Eddystone Beacon",
            altImageTitle: "Avvel X iBeacon & Eddystone Beacon",
            textIconImgPath: "avvel/sold_icon.png",
            enableEnlarge: false,
            bulletPoints: [
              "Sold over 13,000 units and supported customers by publishing technical documentation to aid platform integrations and educating IoT developers globally"
            ]
          },
          {
            imagePath: "avvel/avvel_ultra_beacon.png",
            imageCaption: "Avvel Ultra Outdoor BLE Beacon",
            altImageTitle: "Avvel Ultra Outdoor BLE Beaconn",
            textIconImgPath: "avvel/iot_icon.png",
            enableEnlarge: false,
            bulletPoints: [
              "Developed mobile applications for multinational companies and provided advice on rolling out IoT solutions and built sales channels including Amazon, Rakuten and Google Shopping"
            ]
          }
        ]
      }
    ],
    selectedPortfolio: {}
  }),
  mounted() {
    this.resolveContent();
  },
  methods: {
    resolveContent() {
      this.$el.scrollTop = 0;
      if (!this.$route.params.portfolio_type) {
        this.selectedPortfolio.summaryText =
          "Looks like the page you are looking for is no longer available.";
        this.selectedPortfolio.sectionTitle = "Oh no! Theres been a problem.";
      } else {
        var foundPortfolio = this.portfolios.find(re => {
          return re.portfolioName === this.$route.params.portfolio_type;
        });
        if (!foundPortfolio) {
          this.selectedPortfolio.summaryText =
            "Looks like the page you are looking for is no longer available.";
          this.selectedPortfolio.sectionTitle = "Oh no! Theres been a problem.";
          this.$forceUpdate();
        } else {
          this.selectedPortfolio = foundPortfolio;
        }
      }
    }
  }
};
</script>

<style></style>
