<template>
  <modal
    class="customModal"
    name="imageModalView"
    width="50%"
    height="50%"
    :minWidth="300"
    :maxHeight="1300"
    :maxWidth="1300"
    :adaptive="true"
  >
    <div class="modalContent">
      <div class="closeButton" @click="$modal.hide('imageModalView')">
        <img src="@/assets/images/cancel_icon.png" alt="" />
      </div>
      <img
        v-if="imagePath"
        :src="require('@/assets/portfolio/' + imagePath)"
        alt=""
        width="100%"
        class="modalImage"
      />
    </div>
  </modal>
</template>

<script>
export default {
  name: "ImageModal",
  props: ["imagePath"]
};
</script>

<style scoped>
@media (max-width: 450px) {
  .closeButton img {
    height: 10px;
    width: 10px;
  }
  .closeButton {
    margin: 5px;
    padding: 5px;
  }
}
</style>

<style>
.modalImage {
  object-fit: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.vm--modal {
  background-color: unset !important;
  box-shadow: unset !important;
}

.closeButton {
  position: absolute;
  right: 0px;
  margin: 10px;
  background: white;
  padding: 10px;
  border-radius: 50px;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: 0.4s;
}

.closeButton:hover {
  opacity: 0.5;
}

.closeButton img {
  height: 20px;
  width: 20px;
}
</style>
