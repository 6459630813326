<template>
  <div class="global_container">
    <div class="section_title">Portfolio</div>

    <div class="posts">
      <template v-for="(item, index) in portfolio">
        <div
          class="post"
          :key="index"
          @click="goToPage(item.slug)"
          target="_top"
        >
          <div class="preview">
            <img
              class="preview_port"
              :src="require('@/assets/portfolio/' + item.imagePath)"
              :alt="item.altImgText"
              :height="item.imgHeightLimit"
            />
          </div>
          <div class="tile_description">
            <div class="tile_text">{{ item.project_name }}</div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "Portfolio",
  data: () => ({
    portfolio: [
      {
        project_name: "Project: Order Management Platform",
        imagePath: "oms/oms_dashboard_2.png",
        slug: "order_management_platform",
        altImgText: "Order Management Platform",
        type: "project",
        imgHeightLimit: ""
      },
      {
        project_name: "Project: BLE Beacon Python Library",
        imagePath: "ble_project/BLEBeaconDemo.gif",
        slug: "ble_beacon_library",
        altImgText: "BLE Beacon Library",
        type: "project",
        imgHeightLimit: ""
      },
      {
        project_name: "Experience: Octopus Cash | Octopus Investments",
        imagePath: "octopus/octopus_logo.png",
        slug: "octopus_cash_octopus_investments",
        altImgText: "Octopus Cash | Octopus Investments",
        type: "experience",
        imgHeightLimit: "220px"
      },
      {
        project_name: "Experience: Avvel International",
        imagePath: "avvel/Avvel_Logo.png",
        slug: "avvel_international",
        altImgText: "Avvel International",
        type: "experience",
        imgHeightLimit: "220px"
      }
    ]
  }),
  methods: {
    goToPage(slug) {
      this.$router.push(`/portfolio/${slug}`);
    }
  }
};
</script>

<style scoped>
@media (max-width: 1024px) {
  .section_title {
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 17px;
  }
}
</style>

<style>
.global_container {
  margin-top: 30px;
}

.posts {
  display: flex;
  flex-flow: row wrap;
}

.posts .post {
  background: white;
  margin: 10px;
  height: 400px;
  width: calc((100% / 2) - 20px);
  transition: 0.4s;
  cursor: pointer;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

@media (max-width: 480px) {
  .posts .post {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .posts .post {
    height: 100%;
    padding: 10px;
  }
  .tile_description {
    margin: 5px;
    font-size: 15px;
  }
}

.preview {
  margin: 0 auto;
  height: 80%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tile_description {
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sofiaPro-Medium;
  font-size: 20px;
  color: black;
}

.preview_port {
  margin: 0 auto;
  margin-bottom: 10px;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  object-position: 0%;
}

.preview_port img {
  height: 100%;
  width: 100%;
}

.post:hover {
  opacity: 0.4;
}
</style>
