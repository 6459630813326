<template>
  <div class="footer_container">
    A VueJS app
    <img src="@/assets/images/vue_logo.png" height="20px" alt="" /> hosted on
    the Google Cloud
    <img src="@/assets/images/google_cloud_logo.png" height="20px" alt="" />
    , built by Me.
  </div>
</template>

<script>
export default {
  name: "Footer"
};
</script>

<style>
footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.footer_container img {
  vertical-align: bottom;
  margin: 0px 3px 0px 3px;
}

.footer_container {
  font-family: SofiaPro-Medium;
  font-size: 15px;
  color: black;
}
</style>
