<template>
  <div>
    <div class="row header-container">
      <div class="col my-auto">
        <div class="details_name">
          Theo Bowden
          <span class="details_info">| Product Manager</span>
        </div>
      </div>
      <div class="col">
        <div class="social_links_grp">
          <span class="social_link"
            ><img
              src="@/assets/images/github_icon.png"
              alt="Theo Bowden GitHub"
            /><a href="https://github.com/bowdentheo"
              ><b>github.com</b>/bowdentheo</a
            ></span
          >
          <span class="social_link"
            ><img
              src="@/assets/images/linkedin_icon.png"
              alt="Theo Bowden LinkedIn"
            /><a href="https://linkedin.com/in/theobowden"
              ><b>linkedin.com</b>/theobowden</a
            ></span
          >
          <span class="social_link"
            ><img
              class="email_icon_img"
              src="@/assets/images/email_icon.png"
              alt="Theo Bowden Email"
            /><a href="mailto:theobowden@gmail.com<"
              >theobowden@gmail.com</a
            ></span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header"
};
</script>

<style scoped>
@media (max-width: 450px) {
  .social_links_grp {
    float: unset;
  }
  a {
    font-size: 15px;
  }
  .details_info {
    display: block;
    margin: 10px 0px 10px 0px;
    font-size: 20px;
  }
  .details_name {
    font-size: 30px;
  }
}
</style>
<style>
a {
  color: black !important;
}
.social_links_grp {
  font-family: sofiaPro-Medium;
  letter-spacing: 0.5px;
  width: 340px;
  margin: 0 auto;
  float: right;
}

.social_link img {
  height: 25px;
  margin-right: 10px;
  vertical-align: middle;
}

.email_icon_img {
  width: 27px;
}

.details_name {
  font-size: 40px;
  font-family: sofiaPro-Medium;
  text-align: left;
}

.details_info {
  font-size: 20px;
  font-family: sofiaPro-Light;
}

.social_link {
  margin: 10px;
  text-align: left;
  display: block;
  font-size: 20px;
}

.header-container {
  margin-top: 10px;
}

.details span {
  display: block;
}

.headshot img {
  height: 200px;
}
</style>
